import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textRevealSafety', 'textRevealDailyRoutines', 'textRevealTreatEachOther'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const TextReveal = makeShortcode("TextReveal");
const Img = makeShortcode("Img");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "what-family-rules-do-we-make"
    }}>{`What family rules do we make?`}</h1>
    <p>{`Each family will have different family rules. The most important thing is to keep them `}<strong parentName="p">{`simple`}</strong>{` and `}<strong parentName="p">{`few`}</strong>{`. This means being selective about what you make rules about. Here are some areas where you might want to set rules (click text below to reveal):`}</p>
    <Grid container spacing={3} mdxType="Grid">
  <Grid xs={12} sm={6} item mdxType="Grid">
    <TextReveal id="textRevealSafety" header="Safety" mdxType="TextReveal">
      <p>
        This can include the use of internet/electronic devices, curfews, alcohol use, sex, dating, etc. Here are some
        examples:
      </p>
      <ul>
        <li>A specific time that your teenager has to get home by after spending time with friends (curfew).</li>
        <li>
          Being allowed to go to parties that are adult-supervised, and where the host is a good friend of your
          teenager.
        </li>
      </ul>
    </TextReveal>
  </Grid>
  <Grid xs={12} sm={6} item mdxType="Grid">
    <TextReveal id="textRevealDailyRoutines" header="Daily routines/household chores" mdxType="TextReveal">
      <p>
        This includes homework, laundry, tidying their bedroom, eating at the dinner table, etc. Some examples include:
      </p>
      <ul>
        <li>Household chores that are your teenager’s responsibility to do each week.</li>
        <li>Being home for dinner on school nights.</li>
        <li>Keeping their room clean.</li>
        <li>Homework being completed each night.</li>
        <li>TV and electronic devices being turned off by a certain time each night.</li>
      </ul>
    </TextReveal>
  </Grid>
  <Grid xs={12} sm={6} item mdxType="Grid">
    <TextReveal id="textRevealTreatEachOther" header="How you treat each other" mdxType="TextReveal">
      <p>This includes manners, swearing, hitting, fighting, etc. Here are some specific examples:</p>
      <ul>
        <li>No swearing.</li>
        <li>Respect others and their things.</li>
        <li>No hurting others.</li>
      </ul>
    </TextReveal>
  </Grid>
  <Grid xs={12} sm={6} item mdxType="Grid">
    <Img src="/images/m3/13.svg" alt="Rules Image" mdxType="Img" />
  </Grid>
    </Grid>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      